@import '@americastestkitchen/mise/mise-styles/main.scss';

.mainPageWrapper {
  background-color: $miseColorPrimaryLightTint;
  max-width: 100vw;
  padding: $miseSpacingx8 $miseSpacingx2;
}
.errorWrapper {
  display: flex;
  margin: auto;

  :global(.rounded-container) {
    width: 100%;
  }

  @media screen and (min-width: $miseBreakpointMd) {
    max-width: 1136px;
  }
}

.errorContent {
  padding: $miseSpacingx8;
  display: block;

  @media screen and (min-width: $miseBreakpointMd) {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: space-between;
  }

  .errorBody {
    h2 {
      margin-bottom: 8px;
    }

    .search {
      display: flex;
      align-items: center;
      width: 100%;
      padding-inline: $miseSpacingx5 $miseSpacingx3;
      border: solid 1px $miseColorNeutralDarkShade;
      border-radius: $miseSpacingx12;
      margin: $miseSpacingx3 0 $miseSpacingx8;

      &:focus-within {
        outline-offset: 2px;
        outline: $miseColorNeutralDarkShade dotted 2px;
        box-shadow: none;
      }

      .typed {
        visibility: hidden;
      }

      .label {

        position: absolute;
      }

      .query {
        flex: 1 0 0;
        font-size: 100%;
        line-height: normal;
        vertical-align: middle;
        margin: 0;
        border: none;
        outline: none;
        max-width: 100%;
        padding: $miseSpacingx2 0;
        font-family: revert;
      }

      .submit {
        .spyglassIcon {
          height: $miseSpacingx4;
        }
      }
    }
  }

  .errorMilk {
    margin: auto;
    height: auto;
    width: 150px;
    margin-top: $miseSpacingx8;

    @media screen and (min-width: $miseBreakpointMd) {
      margin: unset;
      width: 204px;
      margin-top: unset;
    }
  }
}

